import React from "react"
import PropTypes from "prop-types"

const Choice = (props) => {

        return(
        <label className="flex mb-4">
  
                <input
                    type="radio"
                    id={props.value}
                    name={props.name}
                    value={props.value}
                    className="inline-block mr-3 ml-0"
                    checked={props.isChecked}
                    onChange={props.onChange}
                />

                <span>{props.label}</span>

            

        </label>
        );
}

export default Choice;

Choice.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
  }