import React from "react"
import PropTypes from "prop-types"
import styles from "./Button.module.scss"

const Button = props => {
    return(<button
        onClick={() => props.handleClick()}
        disabled={props.disabled}
        className={`${styles.primaryButton} py-4 px-6 mt-2 inline-block w-auto font-semibold bg-primary-black cursor-pointer hover:bg-primary-hover transition duration-75 ease-in no-underline text-white font-body border-0 disabled:bg-gray-600 disabled:cursor-not-allowed`}
      >
        <span>{props.text}</span>
      </button>)
}

Button.propTypes = {
    text: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
  }
  

export default Button;