
const questions = [
  {
    questionText: "What Mike's favorite color?",
    choices: [
      { label: "Blue", value: "blue"},
      { label: "Red", value: "red"},
      { label: "Green", value: "green"},
      { label: "Chartreuse", value: "chartreuse"}
    ],
    correctValue: "blue",
    afterSubmitText: "I enjoy the color blue. I'm not entirely sure what 'chartreuse' is.",

  },

  {
    questionText: "What is Mike's favorite drink?",
    choices: [
      { label: "Coca-Cola", value: "cocacola"},
      { label: "Orange Juice", value: "oj"},
      { label: "Cold Brew Coffee", value: "coldbrew"},
      { label: "Iced Coffee", value: "icedcoffee"}
    ],
    correctValue: "coldbrew",
    afterSubmitText: "I enjoy a fine cold brew coffee, which is most definitely not the same as iced coffee.",
  },

  {
    questionText: "What is Mike's favorite movie?",
    choices: [
      { label: "Interstellar", value: "interstellar"},
      { label: "Dark City", value: "darkcity"},
      { label: "Avengers: Endgame", value: "avengers"},
      { label: "High Fidelity", value: "highfidelity"}
    ],
    correctValue: "highfidelity",
    afterSubmitText: "My favorite movie is High Fidelity, though all other choices are in my top ten. Despite it being my favorite movie, the High Fidelity television show that aired on Hulu is even better.",
  },

  {
    questionText: "What existential road trip did Mike take in 2008?",
    choices: [
      { label: "He visited all of the national parks", value: "parks"},
      { label: "He visited all Major League Baseball stadiums", value: "baseball"},
      { label: "He visited a McDonald's in each of the continental states", value: "mcdonalds"},
      { label: "He sought the best hamburger in 23 different state ", value: "hamburglar"}
    ],
    correctValue: "baseball",
    afterSubmitText: "Technically, no answer is completely correct, but the MLB stadium trip is the intended answer. I attempted to see a baseball game at every MLB stadium with my friend Aman. We made it to 25 stadiums over 9 weeks before running out of money (twice).",
  },

  {
    questionText: "Why does Mike occasionally wear very strange looking orange glasses?",
    choices: [
      { label: "They help with migraines", value: "migraine"},
      { label: "He's a big Bono fan", value: "bono"},
      { label: "He thinks they look trendy", value: "trendy"},
      { label: "They're made of ruby quartz and prevent uncontrollable laser beams from firing", value: "cyclops"}
    ],
    correctValue: "migraine",
    afterSubmitText: "Nothing fancy or trendy here: the glasses look odd, but they do wonders for migraines.",
  },

  {
    questionText: "What was Mike's first concert?",
    choices: [
      { label: "Evanscence", value: "evanescence"},
      { label: "Iron Maiden", value: "ironmaiden"},
      { label: "Nine Inch Nails", value: "nin"},
      { label: "The White Stripes", value: "whitestripes"}
    ],
    correctValue: "whitestripes",
    afterSubmitText: "The White Stripes were the first, though I've attended concerts by the other artists (yes, even Evanescence).",
  },

  {
    questionText: "What is Mike's favorite book?",
    choices: [
      { label: "1984", value: "1984"},
      { label: "The Lord of the Rings: The Two Towers", value: "lotr"},
      { label: "Snow Crash", value: "snowcrash"},
      { label: "Neuromancer", value: "neuromancer"}
    ],
    correctValue: "neuromancer",
    afterSubmitText: "Four good books, but Neuromancer is the only one that I regularly re-read.",
  },

  {
    questionText: "What is Mike's favorite band?",
    choices: [
      { label: "Iron Maiden", value: "ironmaiden"},
      { label: "The Offspring", value: "theoffspring"},
      { label: "Linkin Park", value: "linkinpark"},
      { label: "Bad Religion", value: "badreligion"}
    ],
    correctValue: "theoffspring",
    afterSubmitText: "Look: you like what you like. And growing up in California, I like California punk. The Offspring gets the rose over Bad Religion, but both are great.",
  },

  {
    questionText: "What is Mike's favorite video game?",
    choices: [
      { label: "Baldur's Gate II", value: "bgii"},
      { label: "Final Fantasy Tactics", value: "fft"},
      { label: "Half-Life", value: "hl"},
      { label: "Out of the Park Baseball", value: "ootp"}
    ],
    correctValue: "ootp",
    afterSubmitText: "All of these are tremendous games, but I've been playing Out of the Park Baseball for about 15 years. It rules.",
  },

  {
    questionText: "What is Mike's favorite sports memory?",
    choices: [
      { label: "Travis Ishikawa (literally Travis Ishikawa) hits one into right in Game 6 of the 2014 NLCS", value: "2014nlcs"},
      { label: "Barry Bonds throws out a runner in the top of the 9th, then hits a walk-off homerun in the bottom of the inning on his birthday in 2003", value: "2003barry"},
      { label: "Brian Wilson strikes out Nelson Cruz swinging in the 2010 World Series", value: "2010worldseries"},
      { label: "Buster Posey hits a grand slam in the 2012 NLDS off of Mat Latos, banishing his soul to the astral plane", value: "2012nlds"}
    ],
    correctValue: "2010worldseries",
    afterSubmitText: "All of these are cherished memories, but watching Nelson Cruz flail at a high fastball to seal the 2010 World Series will always hold the highest honor.",
  },

]

export default questions;