import React, { Component } from "react"
import PropTypes from "prop-types"
import Choice from "./Choice"
import Button from "../Button/Button"


class Question extends Component {
    constructor(props) {
        super(props)
        this.state = {
          selectionValue: null,
          correctSelected: false,
        }
        this.shuffleChoices();
    }

    choiceChangeHandler = (e) => {
        this.setState({
            selectionValue: e.target.value,
            correctSelected: e.target.value === this.props.correctValue,
        });
    }

    shuffleChoices = () => {
        for (let i = this.props.choices.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [this.props.choices[i], this.props.choices[j]] = [this.props.choices[j], this.props.choices[i]];
        }
      }

     

    render() {
        return(
            <div>
                <h3 className="mb-4">{this.props.questionText}</h3>

                {this.props.choices.map((choice) =>
                    <div><Choice label={choice.label} value={choice.value} name={this.props.name} key={choice.label} onChange={this.choiceChangeHandler}/></div>
                )}

                <div className="mt-4">
                    <Button text="Submit Answer" handleClick={() => this.props.onSubmit(this.state.correctSelected)} disabled={this.state.selectionValue === null} />
                </div>

            </div>
        );
    }
}

export default Question;

Question.propTypes = {
    choices: PropTypes.array,
    name: PropTypes.string,
    correctValue: PropTypes.string,
    questionText: PropTypes.string.isRequired,
    afterSubmitText: PropTypes.string,
    onSubmit: PropTypes.func,
  }