import React from "react"

import questions from "../misc/triviaquestions"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import DetailBlock from "../components/DetailBlock/DetailBlock"
import Divider from "../components/Divider/Divider"
import GameManager from "../components/Trivia2/GameManager"


import Img from "gatsby-image"

const About = props => (
  <Layout>
    <SEO title="About" description="Information about me, my site, and why I sometimes wear orange glasses."/>
    <div className="mt-16 mb-12">
      <h1 className="">About</h1>
      <p className="mb-12">I am Mike. I am classified as human.</p>

      

      <Img className="w-full" fluid={props.data.imgMike.childImageSharp.fluid} alt="It's Mike, but silly."/>

    </div>

  <div>

  </div>


    <DetailBlock title="My Story">
      <p>I grew up in San Francisco, CA and lived in sunny California for most of my life. Over a decade ago, I moved to Milwaukee, WI, much to the bewilderment of friends and family. Here, I learned important life skills, like what to do when your windshield freezes over.</p>

      <p>Like most UX folks, I fell into the industry from adjacent interests: web development, graphic design, and a strong desire to organize things into neat, labeled containers. Lucky for me, those are all useful user experience skills.</p>

      <p> I’d love to tell you all about my technical skills and work experience, but that’s what the rest of the site is for. Here, you can learn about all of the weird stuff through an interactive trivia game that I built so I could figure out how to use callback functions correctly in React.</p>
    </DetailBlock>

    <Divider />

    <DetailBlock title="The Mike Trivia Game">

      <GameManager questions={questions} />

    </DetailBlock>

    <Divider />

    <DetailBlock title="About This Site">
      <p>This site is built with React and uses Gatsby as a static site generator. I hadn’t used Gatsby before, but I had read enough about <a href="https://jamstack.org/" target="_blank" rel="noreferrer">Jamstack</a> sites to want to try a SSG and it seemed well supported.</p>
      <p>My goal was to build something more dynamic and scalable than my prior portfolio sites. That feels like a success so far; it’s far easier to maintain all of this content and it should be less painful to reskin down the road.</p>
 
      <p>Some odds and ends about how the site works:</p>

      <ul>
        <li>“Work” and “Thought” pages are sourced from JDX files at build time</li>
        <li>I used <a href="https://tailwindcss.com/" target="_blank" rel="noreferrer">Tailwind CSS</a> and SCSS modules for the site styling. Tailwind was probably overkill; I hadn’t used it before and was really just looking for an opportunity to try it.</li>
        <li>I migrated my hosting to Netlify, which has been a real joy to use. It watches the site’s Github repo and auto-deploys when changes are pushed to the main branch. It’s as simple as advertised.</li>
      </ul>

      <p>Eventually, I want to implement a headless CMS by sourcing content from a separate data source. Thus far, I’ve toyed with Netlify CMS but had trouble getting my JDX files to recognize React components. It seems like a fixable problem, but it required more effort than needed for the MVP. Hey, there’s always a Phase Two...right?</p>

    </DetailBlock>
  </Layout>
)

export default About

export const pageQuery = graphql`
  query {
    imgMike: file(relativePath: { eq: "itsmike4.png" }) {
      childImageSharp {
        fluid(maxWidth: 950, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;