import React, { Component } from "react"
import PropTypes from "prop-types"
import Question from "./Question"
import Button from "../Button/Button"


class GameManager extends Component {
    constructor(props) {
        super(props)
        this.state = {
            score: 0,
            currentQuestion: 1,
            showSummary: false,
            lastAnswer: false,
            gameStarted: false,
            gameEnded: false,
            summary: "",
        }
    }


    shuffleQuestions = () => {
      for (let i = this.props.questions.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [this.props.questions[i], this.props.questions[j]] = [this.props.questions[j], this.props.questions[i]];
      }
    }

    questionSubmitHandler = (isCorrect) => {  
      if(isCorrect) {
        const newScore = this.state.score + 1;
        this.setState({
          score: newScore,
          lastAnswer: true,
          showSummary: true,
        });
      }

      else {
        this.setState({
          lastAnswer: false,
          showSummary: true,
        });
      }
      
    }

    summaryContinueHandler = () => {
        if(this.state.currentQuestion < this.props.questions.length) {
          const newQuestion = this.state.currentQuestion + 1;
            this.setState({
              currentQuestion: newQuestion,
              showSummary: false,
          });
        }
        else {
          this.setState({
            gameEnded: true,
          })
          this.evaluateScore();
        }
    }

    startGameHandler = () => {
      this.setState({
        score: 0,
        currentQuestion: 1,
        showSummary: false,
        lastAnswer: false,
        gameStarted: true,
        gameEnded: false,
      })

      this.shuffleQuestions();
    }

    evaluateScore = () => {
      switch(this.state.score) {
        case 0:
          this.setState({summary: "Yikes. Perhaps you should leaf through the site a bit more."})
          break;
        case 1:
          this.setState({summary: "Well...you got one question right. That's something, right?"})
          break;
        case 2:
          this.setState({summary: "Only two points? It could have been worse. Not by much, but...glass half full, right?"})
          break;
        case 3:
          this.setState({summary: "Three points! Looks like that last minute studying didn't really help much."})
          break;
        case 4:
          this.setState({summary: "If you got a base hit 40 percent of the time, you'd be in the Hall of Fame. For trivia? Not so much."})
          break;
        case 5:
          this.setState({summary: "50 percent! Call me crazy, but you were guessing on a few of those."})
          break;
        case 6:
          this.setState({summary: "Six points? A noble total, though not quite good enough to reach the playoffs."})
          break;
        case 7:
          this.setState({summary: "70 percent? Not bad, kid. You passed...but only barely."})
          break;
        case 8:
          this.setState({summary: "Believe it or not, eight points is the best score: you clearly know me, but not so well that it weirds me out. Nice work."})
          break;
        case 9:
          this.setState({summary: "Nine points! Impressive, and a little creepy, if I can be honest."})
          break;
        case 10:
          this.setState({summary: "Okay, look: a perfect score has me a little worried. Dial down the Internet stalking, mmkay?"})
          break;
        default:
          this.setState({summary: "Something went wrong when calculating the score summary."})
          break;
      }
    }

    render() {
        return(
            <div>
              
                  {!this.state.gameStarted && 
                    <>
                        <h3>How well do you know Mike?</h3>
                        <p>Answer ten trivia questions to learn strange things about me that don't belong on a résumé.</p>
                        <Button text="Start Game" handleClick={this.startGameHandler}/>
                    </>
                  }

                  {this.state.gameStarted && !this.state.gameEnded && 
                    <>
                        
                        {this.state.showSummary 
                        ?
                        <div>
                          <h3>Your answer was... {this.state.lastAnswer ? <span className='text-green-700 font-bold'>correct!</span> : <span className='text-red-700 font-bold'>incorrect.</span> }</h3>
                          <p className="mb-3">{this.props.questions[this.state.currentQuestion-1].afterSubmitText}</p>
                          <Button text="Continue" handleClick={this.summaryContinueHandler}/>
                          </div> 
                        : 
                        <Question correctValue={this.props.questions[this.state.currentQuestion-1].correctValue} choices={this.props.questions[this.state.currentQuestion-1].choices} name={"set" + this.state.currentQuestion} onSubmit={this.questionSubmitHandler} questionText={"Question #" + this.state.currentQuestion + ": " + this.props.questions[this.state.currentQuestion-1].questionText}/>}   
                    </>
                  }

                  {this.state.gameEnded &&
                    <>
                      <h3>You answered all the questions!</h3>
                      <p>Your final score is...{this.state.score}/10.</p>
                      <p>{this.state.summary}</p>
                      <Button text="Try Again" handleClick={this.startGameHandler}/>
                    </>
                  }

            </div>
        );
    }
}

export default GameManager;

GameManager.propTypes = {
  questions: PropTypes.array,
}